import React from 'react';
import { Container, Typography } from '@mui/material';
import cupcakes from '../../images/about_image.jpeg';

const About = () => {
    return (
        <Container className="about" maxWidth="md" >
            <Typography variant='h1'>About Steph</Typography>
            <Typography variant='p'>
                My name is Stephanie Day and I am the owner/founder of Simply Sweet by Steph. Simply Sweet offers homemade breakfast pastries as well as some other decadent sweet treats. Whether you are looking for homemade cinnamon rolls, muffins, or sweet breads we also offer various desserts. I also love playing around with different cakes and cupcakes. We will always provide different specials to meet everyones sweet tooth and to try new things.
                <img src={cupcakes} alt="Cupcakes" /><br />
                I have always had a passion for being in the kitchen since I was young. In 2013 I graduated from The Art Institute of Pittsburgh in Culinary Arts. After I graduated I landed a position in a family owned cafe. This is where I really developed a new love for baking. I started doing wedding and birthday cakes on the side which was a challenge in teaching myself how to decorate on my own since I did not go to school for Baking and Pastry. Once covid hit I became a stay at home mom and took basically a year off to be present with my 2 kids. Then in 2021 I really started baking out of my tiny kitchen. I would offer cakes for all kinds of occasions and different treats for holidays. 
                <br /><br />
                My mission is to prepare deliciously simple sweets that make people always coming back for more. I believe going simple is the best way to create a palate that is for everyone. 
            </Typography>
        </Container>
    );
}

export default About;

