import React from 'react';
import { AppBar, Button, Container, Toolbar, Menu, Typography, MenuItem, Box, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../images/logo.png';

const Navbar = ({ pages, anchorElNav, handleCloseNavMenu, handleOpenNavMenu }) => {
    const half = Math.ceil(pages.length / 2);    

    const firstHalfPages = pages.slice(0, half)
    const secondHalfPages = pages.slice(-half)

    return (
        <AppBar className="navbar" position="static">
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', marginRight: '20px' }}>
                        {firstHalfPages.map((page) => (
                        <Button
                            key={page.name}
                            name={page.route}
                            onClick={page.onClick}
                            style={{ fontSize: '1.1rem' }}
                            sx={{ my: 2, color: '#535353', display: 'block', mx: 1 }}
                        >
                            {page.name}
                        </Button>
                        ))}
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ color: '#535353', flexgGrow: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        <img className="navbar-logo" src={logo} alt="Simply Sweet by Steph" />
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                        <MenuIcon style={{ color: '#535353' }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem name={page.route} key={page.name} onClick={page.onClick}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box
                        noWrap
                        component="div"
                        sx={{ color: '#535353', flexGrow: 1, display: { xs: 'flex', md: 'none' }, marginLeft: '-10%' }}
                    >
                        <img className="navbar-logo" src={logo} alt="Simply Sweet by Steph" />
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start', marginLeft: '20px' }}>
                        {secondHalfPages.map((page) => (
                        <Button
                            key={page.name}
                            name={page.route}
                            onClick={page.onClick}
                            style={{ fontSize: '1.1rem' }}
                            sx={{ my: 2, color: '#535353', display: 'block', mx: 1 }}
                        >
                            {page.name}
                        </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navbar;
