import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Grid } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';

const Footer = ({ pages }) => {
    return (
        <div className="footer">
            <Container maxWidth="lg">
                <Grid container spacing={{ xs: 1, md: 4 }}>
                    <Grid item xs={12} md={4}>
                        <Typography variant='h4'>Contact</Typography>
                        <Typography variant='p' component='div' style={{ marginBottom: '10px', fontSize: '1rem' }}><EmailIcon style={{ color: '#db4f56' }} /> Steph@simplysweetbysteph.com</Typography>
                        <Typography variant='p' component='div' style={{ marginBottom: '10px', fontSize: '1rem' }}><PhoneIphoneIcon style={{ color: '#db4f56' }} /> 724.674.1154</Typography>
                        <Typography variant='p' component='div' style={{ marginBottom: '10px', fontSize: '1rem' }}><PlaceIcon style={{ color: '#db4f56' }} /> 858 5th Street, Struthers, Ohio 44471</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant='h4'>Social</Typography>
                        <Typography variant='p' component='div' style={{ marginBottom: '10px', fontSize: '1rem' }}><Facebook style={{ color: '#cda19f' }} /> @simplysweetbysteph</Typography>
                        <Typography variant='p' component='div' style={{ marginBottom: '10px', fontSize: '1rem' }}><Instagram style={{ color: '#cda19f' }} /> @ssbysteph</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant='h4'>Navigation</Typography>
                        {
                            pages.map((p, i) => { return (<p key={i}><Link to={`/${p.route}`} style={{ textDecoration: 'none', color: '#000', paddingLeft: '10px' }}>{p.name}</Link></p>) })
                        }
                    </Grid>
                    <Grid item xs={12} textAlign='center'>
                        <Typography className="ytown-mark" variant='p' component='div'>designed and hosted by <a href="https://www.youngstowndigital.com" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>Youngstown Digital</a></Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Footer;
