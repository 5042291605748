import React from 'react';
import { Container, Grid, Typography, Card, CardMedia, CardContent, CardActions } from '@mui/material';
import { Link } from 'react-router-dom';
import CakeIcon from '@mui/icons-material/Cake';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import cupcakes from '../../images/about_image.jpeg';
import menu from '../../images/menu_image.jpg';
import order from '../../images/order_image.jpg';

const Home = () => {
    return (
        <>
            <Container className="home" maxWidth="lg" style={{ textAlign: 'center' }}>
                <div className="hero">
                    <Container maxWidth="md">
                        <div className="heroText">
                            <Typography variant='h1'>Simply Sweet by Steph</Typography>
                            <Typography variant='p'>
                                My mission is to prepare deliciously simple sweets that make people always coming back for more. I believe going simple is the best way to create a palate that is for everyone.
                            </Typography>
                        </div>
                    </Container>
                </div>
            </Container>
            <Container className="home-secondary" maxWidth="md">
                <Grid container spacing={{ xs: 1, md: 4 }}>
                    <Grid item xs={12} md={4}>
                        <Card xs={{ maxWidth: 345 }}>
                            <CardMedia
                                component='img'
                                alt='cakes'
                                height='180'
                                image={cupcakes}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    Check out some of our favorite cakes and cupcakes!
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link to="/cakes">Cakes <CakeIcon /></Link>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card xs={{ maxWidth: 345 }}>
                            <CardMedia
                                component='img'
                                alt='menu'
                                height='180'
                                image={menu}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    Check out our menu for standard pricing information!
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link to="/menu">Menu <MenuBookIcon /></Link>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card xs={{ maxWidth: 345 }}>
                            <CardMedia
                                component='img'
                                alt='cakes'
                                height='180'
                                image={order}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    Click the link below to contact us and place an order!
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link to="/order">Order <BookmarkBorderIcon /></Link>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Home;
