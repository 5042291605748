import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const apiToken = process.env.REACT_APP_API_TOKEN;

axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;

export const getCakeCategories = async () => {
    const response = await axios.post(apiUrl, { "query": "query{listSimplySweetCakeCategories(sort: id_ASC) { data { name description cakes { name description image } } } }" });
    return response.data.data.listSimplySweetCakeCategories.data;
}

export const getMenuCategories = async () => {
    const response = await axios.post(apiUrl, { "query": "query{listSimplySweetMenuCategories(sort: id_ASC) { data { name description additionalDetails items { name description price priceDescription } } } }" });
    return response.data.data.listSimplySweetMenuCategories.data;
}
