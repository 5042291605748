import React, { Fragment } from 'react';
import { Container, Grid, Typography } from '@mui/material';

const Cakes = ({ categories }) => {
    return (
        <Container className="cakes" maxWidth='lg'>
            <Typography variant='h1'>Our Favorites</Typography>
            {
                categories.map((ct, i) => {
                    return (
                        <Fragment key={i}>
                            <Typography variant='h2'>{ ct.name }</Typography>
                            <Grid container spacing={{ xs: 2, md: 4 }}>
                            {
                                ct.cakes.map((ck, i) => {
                                    return (
                                        <Grid key={i} item xs={6} md={3}>
                                            <img src={ck.image} alt="cake" />
                                        </Grid>
                                    )
                                })
                            }
                            </Grid>
                        </Fragment>
                    )
                })
            }
        </Container>
    );
}

export default Cakes;

