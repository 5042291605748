import React from 'react';

const Admin = () => {
    return (
        <>
            {window.location.assign(process.env.REACT_APP_ADMIN_URL)}
        </>
    )
}

export default Admin;
