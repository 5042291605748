import React from 'react';
import { Container, Typography } from '@mui/material';

const Order = () => {
    return (
        <Container maxWidth="lg">
            <Typography variant='h1'>Order</Typography>
        </Container>
    );
}

export default Order;

