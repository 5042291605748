import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

const Contact = () => {
    return (
        <Container className="contact" maxWidth="lg" spacing={{ xs: 1, md: 4 }}>
            <Typography variant='h1'>Contact Us</Typography>
            <Grid container>
                <Grid item xs={12} md={6} style={{ textAlign: 'left', padding: '15px' }}>
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.9653829559966!2d-80.60322409999999!3d41.0478861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8833f024c9553605%3A0x76cbbe5b4b05c5be!2s858%205th%20St%2C%20Struthers%2C%20OH%2044471!5e0!3m2!1sen!2sus!4v1657371947939!5m2!1sen!2sus" 
                        title="map"
                        width="100%" 
                        height="300" 
                        style={{ border: '5px solid #cda19f' }} 
                        allowFullScreen="" 
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </Grid>
                <Grid item xs={12} md={6} style={{ textAlign: 'left', padding: '15px' }}>
                    <Typography variant='p' component='div'><EmailIcon /> Steph@simplysweetbysteph.com</Typography>
                    <Typography variant='p' component='div'><PhoneIphoneIcon /> 724.674.1154</Typography>
                    <Typography variant='p' component='div'><PlaceIcon /> 858 5th Street, Struthers, Ohio 44471</Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Contact;
