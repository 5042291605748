import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Navbar from './components/common/Navbar';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Cakes from './components/pages/Cakes';
import Menu from './components/pages/Menu';
import Contact from './components/pages/Contact';
import Order from './components/pages/Order';
import Footer from './components/common/Footer';
import { useEffect, useState } from 'react';
import Admin from './components/pages/Admin';
import { getCakeCategories, getMenuCategories } from './utils/apiUtil';


function App() {
  const [cakeCategories, setCakeCategories] = useState([]);
  const [menuCategories, setMenuCategories] = useState([]);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();

  const loadCakeCategories = async () => {
    const response = await getCakeCategories();
    setCakeCategories(response);
  }

  const loadMenuCategories = async () => {
    const response = await getMenuCategories();
    setMenuCategories(response); 
  }

  const baseNavigate = (e) => {
    const route = e.currentTarget.getAttribute("name")
    handleCloseNavMenu();
    navigate(`/${route}`);
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pages = [
    { name: 'Home', route: '', onClick: baseNavigate },
    { name: 'Contact', route: 'contact', onClick: baseNavigate },
    { name: 'About', route: 'about', onClick: baseNavigate },
    { name: 'Cakes', route: 'cakes', onClick: baseNavigate },
    { name: 'Menu', route: 'menu', onClick: baseNavigate },
    { name: 'Order', route: 'order', onClick: () => window.location.href = "mailto:steph@simplysweetbysteph.com" },
  ];

  useEffect(() => {
    loadCakeCategories();
    loadMenuCategories();
  }, [])

  return (
    <>
      <Navbar pages={pages} anchorElNav={anchorElNav} setAnchorElNav={setAnchorElNav} handleOpenNavMenu={handleOpenNavMenu} handleCloseNavMenu={handleCloseNavMenu} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/cakes" element={<Cakes categories={cakeCategories} />} />
        <Route path="/order" element={<Order />} />
        <Route path="/menu" element={<Menu categories={menuCategories} />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
      <Footer pages={pages} />
    </>
  );
}

export default App;
