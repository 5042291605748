import React, { Fragment, useState } from 'react';
import { Container, Grid, Modal, Typography, Box } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Menu = ({ categories }) => {
    const [selectedModal, setSelectedModal] = useState(null);

    const handleToggleOpen = (i) => setSelectedModal(i);

    const handleToggleClose = () => setSelectedModal(null);

    return (
        <Container className="menu" maxWidth='md' spacing={{ xs: 2, md: 4 }}>
            <Typography variant='h1'>Menu</Typography>
            {
                categories.map((ct, i) => {
                    return (
                        <Fragment key={i}>
                            <Fragment>
                                <Typography variant='h2'>{ ct.name } { ct.additionalDetails && <span onClick={() => handleToggleOpen(i)} style={{ color: 'blue', fontStyle: 'underlined', cursor: 'pointer' }}>(Additional Info)</span> }</Typography>
                                <Grid container>
                                    {
                                        ct.items.map((it, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    <Grid item xs={6} style={{ textAlign: 'left' }}>
                                                        <Typography variant='p' component='div'>{ it.name }...</Typography>
                                                        <Typography variant='p' component='div' style={{ fontSize: '1rem' }}>{ it.description }</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                                                        <Typography variant='p' component='div'>{ it.priceDescription }</Typography>
                                                    </Grid>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </Grid>
                            </Fragment>
                            <Modal
                            open={selectedModal === i}
                            onClose={handleToggleClose}
                            >
                                <Box sx={style}>
                                    <img src={ct.additionalDetails} alt='Additional Details' />
                                </Box>
                            </Modal>
                        </Fragment>
                    )
                })
            }
        </Container>
    );
}

export default Menu;

